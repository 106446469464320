import React from 'react';
import {TestimonialsSection,DesktopLayout,MobileLayout,TestimonialsSingleLayout,ImageHolder,AuthorLayout,
    Author,AuthorName,Designation,QuotesHolder,TestimonialsText,QuotesIcon
} from './testimonials.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const Testimonials  = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        testimonialsJson{
                Testimonials{
                    Details{
                        Image{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        AuthorName
                        Designation
                        TestimonialsText
                    }
                }
            }
        }
  `);

    return (
           <TestimonialsSection>
               <Container>
                   <DesktopLayout>
                   <Row>
                        <Col md={6}>
                        {
                            JSONData.testimonialsJson.Testimonials.Details.map((item,idx) => {
                                return idx%2===0 && <TestimonialsSingleLayout>
                                    <ImageHolder>
                                        <GatsImg 
                                            fluid={item.Image.childImageSharp.fluid} 
                                            alt=""
                                            imgStyle={{
                                                borderRadius:"100%",
                                                marginBottom:"-30px",
                                                border:"3px solid #fff"
                                            }}
                                        />
                                    </ImageHolder>
                                    <AuthorLayout>
                                        <Author>
                                            <AuthorName>
                                                {item.AuthorName} 
                                            </AuthorName>
                                            <Designation>
                                                {item.Designation} 
                                            </Designation>
                                        </Author>
                                        <QuotesHolder>
                                            <QuotesIcon/>
                                        </QuotesHolder>
                                    </AuthorLayout>
                                    <TestimonialsText>
                                        {item.TestimonialsText}
                                    </TestimonialsText>
                                </TestimonialsSingleLayout>
                            })
                        }
                        </Col>
                        <Col md={6}>
                        {
                                JSONData.testimonialsJson.Testimonials.Details.map((item,idx) => {
                                    return idx%2!==0 && <TestimonialsSingleLayout>
                                        <ImageHolder>
                                            <GatsImg 
                                                fluid={item.Image.childImageSharp.fluid} 
                                                alt=""
                                                imgStyle={{
                                                    borderRadius:"100%",
                                                    marginBottom:"-30px",
                                                    border:"3px solid #fff"
                                                }}
                                            />
                                        </ImageHolder>
                                        <AuthorLayout>
                                            <Author>
                                                <AuthorName>
                                                    {item.AuthorName} 
                                                </AuthorName>
                                                <Designation>
                                                    {item.Designation} 
                                                </Designation>
                                            </Author>
                                            <QuotesHolder>
                                                <QuotesIcon/>
                                            </QuotesHolder>
                                        </AuthorLayout>
                                        <TestimonialsText>
                                            {item.TestimonialsText}
                                        </TestimonialsText>
                                    </TestimonialsSingleLayout>
                                })
                            }
                            </Col>
                   </Row>
                   </DesktopLayout>
                   <MobileLayout>
                   <Row>
                        {
                            JSONData.testimonialsJson.Testimonials.Details.map((item,idx) => {
                                return  <Col md={6}>
                                <TestimonialsSingleLayout>
                                    <ImageHolder>
                                        <GatsImg 
                                            fluid={item.Image.childImageSharp.fluid} 
                                            alt=""
                                            imgStyle={{
                                                borderRadius:"100%",
                                                marginBottom:"-30px",
                                                border:"3px solid #fff"
                                            }}
                                        />
                                    </ImageHolder>
                                    <AuthorLayout>
                                        <Author>
                                            <AuthorName>
                                                {item.AuthorName} 
                                            </AuthorName>
                                            <Designation>
                                                {item.Designation} 
                                            </Designation>
                                        </Author>
                                        <QuotesHolder>
                                            <QuotesIcon/>
                                        </QuotesHolder>
                                    </AuthorLayout>
                                    <TestimonialsText>
                                        {item.TestimonialsText}
                                    </TestimonialsText>
                                </TestimonialsSingleLayout>
                                </Col>
                            })
                        }
                        </Row>
                    </MobileLayout>
               </Container>
           </TestimonialsSection>
    );
}
export default Testimonials;